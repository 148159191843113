<template>
  <div :class="styles.footerNavbar">
    <div :class="styles.footerNavbarRow">
      <div :class="styles.footerNavbarWrapper">
        <a
          v-for="link in linksTop"
          :key="link.href"
          :href="link.href"
          :title="link.screenReaderTitle"
          :class="styles.footerNavbarItem"
        >
          {{ link.title }}
        </a>
        <a
          href="javascript:;"
          data-bs-toggle="modal"
          data-bs-target="#js-form-contact-us-modal"
          role="button"
          :class="styles.footerNavbarItem"
        >
          Contact Us
        </a>
      </div>

      <div :class="styles.footerNavbarItemSeparator" />

      <div :class="styles.footerNavbarSocialsWrapper">
        <a
          href="https://facebook.com/irishdogs"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Dogs.ie on Facebook"
          :class="styles.footerNavbarItem"
        >
          <i class="fab fa-facebook-square fa-lg" />
        </a>
        <a
          href="https://twitter.com/irishdogs"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Dogs.ie on Twitter"
          :class="[styles.footerNavbarItem, 'ms-2']"
        >
          <i class="fab fa-twitter-square fa-lg" />
        </a>
      </div>
    </div>

    <div :class="styles.footerNavbarRow">
      <div :class="styles.footerNavbarWrapper">
        <a
          v-for="link in linksBottom"
          :key="link.href"
          :href="link.href"
          :title="link.screenReaderTitle"
          :class="styles.footerNavbarItem"
        >
          <IconReviewStar v-if="link.href === '/reviews'" />
          <IconReviewStar v-if="link.href === '/reviews'" />
          <IconReviewStar v-if="link.href === '/reviews'" />
          <IconReviewStar v-if="link.href === '/reviews'" />
          <IconReviewStar v-if="link.href === '/reviews'" />
          {{ link.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
/* Components */
import IconReviewStar from '@components/Shared/Icons/IconReviewStar';

/* Styles */
import styles from './FooterNavbar.module.scss';

/* Constants */
const linksTop = [
  {
    href: '/about',
    title: 'About Us',
    screenReaderTitle: 'About dogs.ie',
  },
  {
    href: '/faq',
    title: 'FAQ',
    screenReaderTitle: 'FAQ',
  },
  {
    href: '/terms',
    title: 'Terms',
    screenReaderTitle: 'Terms & Conditions',
  },
];

const linksBottom = [
  {
    href: '//help.dogs.ie/',
    title: 'Website Help',
    screenReaderTitle: 'Help',
  },
  {
    href: '/reviews',
    title: 'Reviews',
    screenReaderTitle: 'Dogs.ie Reviews',
  },
  {
    href: '/open',
    title: 'Open Data',
    screenReaderTitle: 'Open Data',
  },
  {
    href: '/press',
    title: 'Press',
    screenReaderTitle: 'Press',
  },
];
</script>
