<template>
  <nav :class="styles.header">
    <Container :class="styles.headerWrapper">
      <HeaderLogo
        title="Dogs.ie"
      />
      <HeaderNavbarToggler
        @navbar-opened-change="handleNavbarOpenedChange"
      />
      <HeaderNavbar
        :navbar-opened="navbarOpened"
      />
    </Container>
  </nav>
</template>

<script setup>
import { ref } from 'vue';

/* Components */
import Container from '@components/Shared/Layout/Container/Container';
import HeaderLogo from '@components/Shared/Layout/Header/HeaderLogo';
import HeaderNavbar from '@components/Shared/Layout/Header/HeaderNavbar';
import HeaderNavbarToggler from '@components/Shared/Layout/Header/HeaderNavbarToggler';

/* Styles */
import styles from './Header.module.scss';

/* Data */
const navbarOpened = ref(false);

/* Methods */
const handleNavbarOpenedChange = (opened) => {
  navbarOpened.value = opened;
};
</script>
