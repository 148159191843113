<template>
  <nav aria-label="breadcrumb">
    <ol
      :class="styles.breadcrumb"
      itemscope
      itemtype="https://schema.org/BreadcrumbList"
    >
      <li
        v-for="(item, index) in breadcrumbItems"
        :key="item.path"
        :class="[styles.breadcrumbItem, { [styles.active]: usePage().url === item.path, 'd-none d-lg-block': index === 3 }]"
        itemscope
        itemprop="itemListElement"
        itemtype="https://schema.org/ListItem"
      >
        <a
          v-if="usePage().url !== item.path"
          itemprop="item"
          :href="item.path"
        >
          <span itemprop="name">
            {{ item.name }}
          </span>
        </a>
        <span
          v-else
          itemprop="name"
        >
          {{ item.name }}
        </span>
        <meta
          itemprop="position"
          :content="index + 1"
        >
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

/* Styles */
import styles from './Breadcrumb.module.scss';

/* Props */
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

/* Data */
const breadcrumbItems = ref([
  {
    name: 'Home',
    path: '/',
  },
  ...props.items,
]);
</script>
