<template>
  <button
    :class="[styles.headerNavbarToggler, { [styles.opened]: navbarOpened }]"
    aria-label="Toggle navigation"
    type="button"
    @click="
      navbarOpened = !navbarOpened;
      emit('navbar-opened-change', navbarOpened);
    "
  >
    <span />
  </button>
</template>

<script setup>
import { ref } from 'vue';

/* Styles */
import styles from './HeaderNavbarToggler.module.scss';

/* Emits */
const emit = defineEmits(['navbar-opened-change']);

/* Data */
const navbarOpened = ref(false);
</script>
