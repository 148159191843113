<template>
  <!-- TODO: Replace the second 'a' with 'Link' -->
  <Component
    :is="externalLink ? 'a' : (name === 'Logout' ? 'Link' : 'a')"
    :class="[
      styles.navbarItemDropdownItem,
      { [styles.active]: activeLink },
      { [styles.hideOnDesktop]: hideOnDesktop },
      { [styles.hideOnTabletLandscape]: hideOnTabletLandscape },
      { [styles.hideOnTablet]: hideOnTablet },
      { [styles.hideOnMobile]: hideOnMobile },
    ]"
    :target="externalLink ? '_blank' : '_self'"
  >
    <i
      v-if="icon"
      :class="`${icon} me-1`"
    /> {{ name }}
  </Component>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { usePage } from '@inertiajs/vue3';

/* Styles */
import styles from './HeaderNavbarItemDropdownItem.module.scss';

/* Props */
defineProps({
  name: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  hideOnDesktop: {
    type: Boolean,
    required: false,
  },
  hideOnTabletLandscape: {
    type: Boolean,
    required: false,
  },
  hideOnTablet: {
    type: Boolean,
    required: false,
  },
  hideOnMobile: {
    type: Boolean,
    required: false,
  },
});

/* Computed */
const activeLink = computed(() => route().t.url + usePage().url.split('?')[0] === useAttrs().href);
const externalLink = computed(() => !useAttrs().href.includes('dogs.ie') && useAttrs().href.includes('http'));
</script>
