<template>
  <li
    v-on-click-outside="closeDropdown"
    :class="[
      styles.navbarItemDropdown,
      { [styles.active]: activeLink },
      { [styles.hideOnDesktop]: hideOnDesktop },
      { [styles.hideOnTabletLandscape]: hideOnTabletLandscape },
      { [styles.hideOnTablet]: hideOnTablet },
      { [styles.hideOnMobile]: hideOnMobile },
    ]"
  >
    <button
      :class="[styles.navbarItemDropdownButton, { [styles.active]: dropdownOpened }]"
      type="button"
      @click="toggleDropdown"
    >
      <i
        v-if="icon"
        :class="`${icon} me-1`"
      /> {{ name }}
    </button>
    <div :class="[styles.navbarItemDropdownContent, { [styles.opened]: dropdownOpened }]">
      <template
        v-for="(group, groupIndex) in items"
        :key="`group-${groupIndex}`"
      >
        <div
          v-if="group.name"
          :class="styles.navbarItemDropdownHeader"
        >
          <small>{{ group.name }}</small>
        </div>
        <HeaderNavbarItemDropdownItem
          v-for="item in group.children"
          :key="item.path"
          :name="item.name"
          :href="item.path"
          :icon="item.icon"
          :hide-on-desktop="item.hideOnDesktop"
          :hide-on-tablet-landscape="item.hideOnTabletLandscape"
          :hide-on-tablet="item.hideOnTablet"
          :hide-on-mobile="item.hideOnMobile"
        />
        <div
          v-if="groupIndex < items.length - 1"
          :class="styles.navbarItemDropdownContentDivider"
        />
      </template>
      <slot name="appendContent" />
    </div>
  </li>
</template>

<script setup>
import { computed, ref } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

/* Composables */
import { usePage } from '@inertiajs/vue3';

/* Components */
import HeaderNavbarItemDropdownItem from '@components/Shared/Layout/Header/HeaderNavbarItemDropdownItem';

/* Styles */
import styles from './HeaderNavbarItemDropdown.module.scss';

/* Props */
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  items: {
    type: Array,
    required: true,
  },
  hideOnDesktop: {
    type: Boolean,
    required: false,
  },
  hideOnTabletLandscape: {
    type: Boolean,
    required: false,
  },
  hideOnTablet: {
    type: Boolean,
    required: false,
  },
  hideOnMobile: {
    type: Boolean,
    required: false,
  },
});

/* Data */
const dropdownOpened = ref(false);

/* Computed */
const activeLink = computed(() => {
  return props.items.some((group) => {
    if (!group.children) {
      return false;
    }

    return group.children.some((child) => route().t.url + usePage().url === child.path);
  });
});

/* Methods */
const toggleDropdown = () => {
  dropdownOpened.value = !dropdownOpened.value;
};

const closeDropdown = () => {
  dropdownOpened.value = false;
};
</script>
