<template>
  <div :class="[styles.headerNavbar, { [styles.opened]: navbarOpened }]">
    <ul :class="styles.headerNavbarNav">
      <HeaderNavbarItem
        name="For Sale"
        path="/sale"
        icon="fad fa-paw"
      />
      <HeaderNavbarItem
        name="For Stud"
        path="/stud"
        icon="fad fa-dog"
      />
      <HeaderNavbarItem
        name="Vets"
        path="/vets"
        icon="fad fa-user-md"
      />
    </ul>
    <ul :class="styles.headerNavbarNav">
      <HeaderNavbarForAdmins
        v-if="$page.props.sharedData.user && $page.props.sharedData.user.roleName === 'admin'"
      />
      <HeaderNavbarForAdvertisers
        v-if="$page.props.sharedData.user && $page.props.sharedData.user.roleName === 'advertiser'"
      />
      <HeaderNavbarForBuyers
        v-if="$page.props.sharedData.user && $page.props.sharedData.user.roleName === 'buyer'"
      />
      <HeaderNavbarForAuthenticatedUsers
        v-if="$page.props.sharedData.user"
      />
      <HeaderNavbarForGuests
        v-else
      />
    </ul>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';

/* Components */
import HeaderNavbarItem from '@components/Shared/Layout/Header/HeaderNavbarItem';

/* Styles */
import styles from './HeaderNavbar.module.scss';

/* Components (Async) */
const HeaderNavbarForAdmins = defineAsyncComponent(() => import('@components/Shared/Layout/Header/HeaderNavbarForAdmins'));
const HeaderNavbarForAdvertisers = defineAsyncComponent(() => import('@components/Shared/Layout/Header/HeaderNavbarForAdvertisers'));
const HeaderNavbarForAuthenticatedUsers = defineAsyncComponent(() => import('@components/Shared/Layout/Header/HeaderNavbarForAuthenticatedUsers'));
const HeaderNavbarForBuyers = defineAsyncComponent(() => import('@components/Shared/Layout/Header/HeaderNavbarForBuyers'));
const HeaderNavbarForGuests = defineAsyncComponent(() => import('@components/Shared/Layout/Header/HeaderNavbarForGuests'));

/* Props */
defineProps({
  navbarOpened: {
    type: Boolean,
    required: true,
  },

});
</script>
